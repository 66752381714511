.review-container{
    display:flex;
    flex-direction: row;
    align-items: start;
    gap: 5%;
    .review-titlebox{
        position: relative;
        width: 250px;
        height: 200px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: #D9D9D9;
        border-radius: 10%;
        padding:32px;
        h2 {
            font-size: 25px;
        }
        h3 {
            font-style: normal ;
            font-weight: 500;
            font-size: 15px;
        }
    }
    .review-content-wrapper{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 20px;
        .reviewbox{
            display:flex;
            justify-content: center;
            cursor: pointer;
            isolation: isolate;
            .reviewbox-bg{
                width: 100px;
                height: 200px;
                background-size: cover;
                background-repeat: no-repeat;
                position: absolute;
                z-index:-1;
            }
        }
        
    }
    
}