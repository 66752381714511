.swiper {
    width: 100%;
    display: inline;
}
.swiper-wrapper {
    width:100vw;
}
.swiper-slide {
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.home1-wrapper {
    background: linear-gradient(146.08deg, #AFC1FF 35.07%, #0B1E83 77.75%);
    width:100vw;
    height: 60vw;
    .home1-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 150px;
        .home1-catchphrase{
            font-family: 'Inter';
            font-style: normal;
            display: flex;
            justify-content: center;
            font-weight: 700;
            font-size: calc(4.5vw);
            line-height: 150%;
            /* identical to box height, or 105px */
            background: linear-gradient(90deg, #1F2D76 0%, #377DB7 79.5%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
        .home1-imgbox {
            display: flex;
            justify-content: center;
            filter: drop-shadow(30px 20px 4px rgba(0, 0, 0, 0.25));
        }
    }
}

h2,h3 {
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 25px;
line-height: 150%;
}
.home2-wrapper{
    width: 100vw;
    height: 700px;
    .home2-container {
        padding-top: 150px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        .home2-title {
            font-size: 60px;
        }
        .home2-menu-container {
            padding-top: 2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            .menubox {
                cursor: pointer;
                width: 317px;
                height: 390px;
                display: flex;
                justify-content: center;
                color: rgba(0, 0, 0, 0.95);
                isolation: isolate;
                .menubox-bg{
                    width: 317px;
                    height: 390px;
                    border-radius: 20%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: absolute;
                    z-index: -1;
                }
                .menubox-content{
                    text-align: center;
                    padding-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img_description{
                        display: none;
                    }
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
            .menubox:hover {
                .menubox-bg{
                    opacity: 0.5;
                }
                .img_description{
                    display: block;
                    padding-top: 30px;
                }
            }


            #ami-menubox {
                h3 {
                    width: 179px;
                }
            }
        }
    }
}

.home3-wrapper {
    width: 100vw;
    height: 30vw;
    background: #D9D9D9;
    margin-top:30px;
    .home3-container {
        padding-top: 150px;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        .handshake-icon {
            height:70px;
            width: 70px
        }
        h3 {
            font-size: 40px;
        }
    }
}

@media screen and (max-width: 1420px) {
    .home1-wrapper {
        height: 80vw;
        .home1-catchphrase {
            margin : 0;
        }
        .home1-imgbox > img {
            width: 45vw;
        }
    }
    .home2-wrapper{
        .home2-container{
            .home2-title{
                font-size: 50px;
            }
        }
        height: 700px;
    }
    .home3-wrapper {
        height: 35vw;
        .home3-container {
            padding-top: 100px;
        }
    }
}
@media screen and (max-width: 1200px) {
    .home2-wrapper {
        height: 450px;
        .home2-container{
            padding-top: 85px;
            .home2-title {
                font-size: 40px;
            }
            .home2-menu-container {
                padding-top: 0.5rem;
                .menubox {
                    width: 200px;
                    height: 240px;
                    .menubox-bg{
                        width: 200px;
                        height: 240px;
                    }
                    .menubox-content {
                        h3 {
                            font-size: 20px;
                        } 
                    }
                }
                #ami-menubox {
                    h3 {
                        width: 150px;
                    }
                }
            }
        }
    }
    .home3-wrapper{
        height: 40vw;
        .home3-container {
            padding-top: 80px;
            gap: 1.5rem;
            h3 {
                font-size: 30px;
            }
        }
    }
}
@media screen and (max-width: 768px){
    .home1-wrapper {
        .home1-container {
            padding-top: 120px;
            justify-items: center;
            .home1-catchphrase {
                font-size: 30px;
                width: 270px;
                display: flex;
                align-self: center;
                text-align: center;
            }
        }
    }    
    .home2-wrapper{
        height: 300px;
        .home2-container {
            padding-top: 60px;
            gap: 20px;
            .home2-title {
                font-size: 30px;
            }
            .home2-menu-container {
                padding-top: 5px;
                .menubox {
                    width: 120px;
                    height: 150px;
                    .menubox-bg{
                        width: 120px;
                        height: 150px;
                    }
                    .menubox-content {
                        padding-top: 1rem;
                        h3 {
                            font-size: 12px;
                        }
                        p {
                            font-size: 8px;
                        }
                        .img_description {
                            overflow: auto;
                            padding-top: 5px;
                        }
                    }
                }
                #ami-menubox {
                    h3 {
                        width: 115px;
                    }
                }
            }
        }
    }
    .home3-wrapper {
        height: 50vw;
        .home3-container {
            padding-top: 10vw;
            .handshake-icon {
                height:50px;
                width:50px;
            }
            h3 {
                font-size: 20px;
            }
            .home3-logos {
                padding-top: 1rem;
            }
        }
    }
}
@media screen and (max-width: 600px){
    .home1-wrapper {
        .home1-container {
            .home1-catchphrase {
                width: 230px;
                font-size: 25px;
            }
        }
        height: 120vw;
    }
    .home2-wrapper {
        h2 {
            font-size: 12px;
        }
        height: 200px;
        .home2-container {
            width: 90%;
            padding-top: 40px;
            justify-self: center;
            .home2-title {
                font-size: 20px;
            }
            .home2-menu-container {
                padding-top: 0.5rem;
                .menubox {
                    width: 90px;
                    height: 100px;
                    .menubox-bg{
                        width: 80px;
                        height: 100px;
                    }
                    .menubox-content {
                        padding-top: 0.5rem;
                        h3 {
                            font-size: 9px;
                        }
                    }
                }
                #ami-menubox {
                    h3 {
                        width: 70px;
                    }
                }
            }
        }
    }
    .home3-wrapper {
        height: 60vw;
        .home3-container {
            gap: 4vw;
        }
    }
}