.footer-wrapper {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px 20px;
    overflow: auto;
    h3 {
        text-align: center;
        color: #686D76; 
        line-height: 90%;
        font-size: 12px;
        font-weight: 500;
    }
    margin-bottom: 0.5rem;
}